section {
    &.connections {
        display: flex;
        padding-top: 81px;
        padding-bottom: 81px;

        &.browser {
            flex-direction: row;
        }

        &.mobile {
            flex-direction: column;
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .container {
            display: flex;

            h2 {
                font-weight: 800;
                line-height: 58.51px;
                color: white;
                text-transform: uppercase;
            }

            p {
                font-weight: 600;
                line-height: 34.59px;
                color: white;
            }

            .link {
                display: grid;
                grid-template-columns: repeat(3, 52.76px);
                gap: 30px;
            }

            &.browser {
                flex-direction: row;
                gap: 74px;

                .info-container {
                    max-width: 400px;
                }

                h2 {
                    font-size: 48px;
                    margin-bottom: 50px;
                }
                p {
                    font-size: 28.38px;
                    margin-bottom: 50px;
                }
                .map {
                    width: 934px;
                }
            }
    
            &.mobile {
                max-width: 80%;
                flex-direction: column-reverse;
                gap: 20px;
                h2 {
                    font-size: 36px;
                    margin-bottom: 25px;
                }
                p {
                    font-size: 22.38px;
                    margin-bottom: 25px;
                }
                .map {
                    width: 100%;
                }
            }
        }
    }
}
footer {
    display: flex;
    justify-content: center;

    .container {
        display: flex;

        &.browser {
            flex-direction: row;
            width: 1400px;
            max-width: 1400px;
            justify-content: start;
            margin: unset;
            gap: 56px;
        }
    
        &.mobile {
            flex-direction: column;
            width: 100%;
            max-width: 80%;
            gap: 26px;
        }
    }

    &.browser {
        flex-direction: row;
        padding-top: 74px;
        padding-bottom: 104px;
    }

    &.mobile {
        flex-direction: column;
        padding-top: 24px;
        padding-bottom: 24px;
    }

    .button {
        &.hgp {
            width: 193.66px;
            height: 121.17px;
        }

        &:not(.hgp) {
            img {
                height: 65.07px;
            }
        }

        &.browser:not(.hgp) {
            margin-bottom: 50px;
        }
    }

    .footer-item {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: inherit;
    }

    a {
        font-weight: 600;
        font-size: 20px;
        line-height: 24.38px;
        color: white;
        text-decoration: none;
    }
}
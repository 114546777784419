section {
    &.info {
        display: flex;
        justify-content: center;
    
        &.mobile {
            margin: 0 24px;
        }

        .container {
            display: grid;
            gap: 44px;
            margin: 84px auto;

            &.browser {
                grid-template-columns: repeat(3, 439px);
            }
    
            &.mobile {
                grid-template-columns: repeat(1, 1fr);
                
                h4 {
                    font-size: 18.38px;
                    margin: 18px 0;
                }
        
                p {
                    font-size: 18.38px;
                    line-height: 24.59px;
                }
            }

            div {

                img {
                    width: 100%;
                }

                h4 {
                    font-weight: 800;
                    font-size: 28.38px;
                    color: white;
                    margin-top: 36px;
                    margin-bottom: 38px;
                }
        
                p {
                    font-weight: 600;
                    font-size: 28.38px;
                    color: white;
                    line-height: 34.59px;
                }
            }
        }
    }
}
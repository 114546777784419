@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Mansalva&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'sass/header';
@import 'sass/video';
@import 'sass/description';
@import 'sass/info';
@import 'sass/ourTeam';
@import 'sass/connections';
@import 'sass/footer';

.landingPage {
    background-color: black;
}

.landingPage * {
    margin: 0 auto;
    padding: 0;
    font-family: 'Inter', sans-serif;
    font-family: 'Mansalva', sans-serif;
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
}
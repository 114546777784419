section {
    &.our-team {
        display: flex;
        flex-direction: column;

        h2 {
            font-weight: 800;
            line-height: 58.51px;
            text-align: center;
            color: white;
            text-transform: uppercase;
            margin-bottom: 44px;
        }

        &.browser {
            padding-top: 50px;
            padding-bottom: 67px;
            
            h2 {
                font-size: 48px;
            }
        }

        &.mobile {
            padding-top: 30px;
            padding-bottom: 30px;
            
            h2 {
                font-size: 36px;
            }
        }

        .container {
            margin: auto;
            display: grid;
            gap: 19px;

            div {
                text-align: center;

                img {
                    width: 185px;
                    margin-bottom: 29px;
                }

                h4 {
                    font-weight: 800;
                    text-transform: uppercase;
                    color: white;
                    font-size: 24.41px;
                    line-height: 29.76px;
                }

                p {
                    font-weight: 400;
                    color: white;
                    font-size: 24.41px;
                    line-height: 29.76px;
                }
            }

            &.browser {
                grid-template-columns: repeat(7, 185px);
            }
    
            &.mobile {
                grid-template-columns: repeat(1, 1fr);
                max-width: 80%;
            }
        }
    }
}

// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    section {
        &.our-team {
            .container {
                &.mobile {
                    grid-template-columns: repeat(1, 1fr);
                    max-width: 80%;
                }
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    section {
        &.our-team {
            .container {
                &.mobile {
                    grid-template-columns: repeat(2, 1fr);
                    max-width: 80%;
                }
            }
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    section {
        &.our-team {
            .container {
                &.mobile {
                    grid-template-columns: repeat(3, 1fr);
                    max-width: 80%;
                }
            }
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    section {
        &.our-team {
            .container {
                &.mobile {
                    grid-template-columns: repeat(4, 1fr);
                    max-width: 80%;
                }
            }
        }
    }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { 
    section {
        &.our-team {
            .container {
                &.mobile {
                    grid-template-columns: repeat(7, 1fr);
                    max-width: 80%;
                }
            }
        }
    }
}
section {
    &.description {

        &.browser {
            padding-top: 61px;
            padding-bottom: 298px;
            font-size: 28.38px;
        }

        &.mobile {
            padding-top: 30px;
            padding-bottom: 128px;
            font-size: 14px;
        }

        .container {
            &.browser {
                max-width: 879px;
            }
    
            &.mobile {
                max-width: 80%;
            }

            h1 {
                font-weight: 800;
                text-transform: uppercase;
                color: white;
                text-shadow: 0 4px 4px #00000040;
        
                &.browser {
                    font-size: 64pt;
                    margin-bottom: 50px;
                }
        
                &.mobile {
                    font-size: 22pt;
                    margin-bottom: 3px;
                }
            }

            p {
                font-weight: 600;
                color: white;
            }

            .button {
                margin: unset;

                &.browser {
                    img {
                        height: 65.07px;
                    }
                }
        
                &.mobile {
                    img {
                        height: 36px;
                    }
                }
            }
        }
    }
}
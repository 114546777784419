header {
    display: flex;

    .overlay {
        background-color: rgba(0,0,0,.4);
    }

    .container {
        position: absolute;
        width: 100%;

        &:not(.video) {
            bottom: 50px;
        }

        div {
            display: flex;
            flex-direction: column;
            margin-top: auto;
            gap: 13px;

            div {
                flex-direction: row;
                &.browser {
                    gap: 50px;
                }
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        margin-bottom: 20px;
        gap: 13px;

        .button-container {
            display: flex;
            flex-direction: row;
            &.browser {
                gap: 50px;
            }
            &.mobile {
                gap: 10px;
            }
        }
    }

    .button {
        &.browser {
            img {
                height: 65.07px;
            }
        }

        &.mobile {
            img {
                height: 36px;
            }
        }
    }

    h1 {
        font-weight: 800;
        text-transform: uppercase;
        color: white;
        text-shadow: 0 4px 4px #00000040;

        &.browser {
            font-size: 81.8pt;
        }

        &.mobile {
            font-size: 22pt;
        }
    }
}